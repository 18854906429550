<div class="container" [class.teams]="teamsApp">
    <div class="left-container">
        <!-- Table header -->

        <div class="table-header" *ngIf="!options?.headerOptions?.hide">
            <app-table-header
                *ngIf="options.headerOptions"
                class="w100p"
                [options]="options.headerOptions"
                [selectedKeys]="selectedKeys"
                [selectedFolderKeys]="selectedFolderKeys"
                [folderId]="folderId"
                (bulkEditOutput)="handleBulkEdit($event)"
                (bulkMoveOutput)="chooseFolder(null)"
                (exportReportOutput)="handleReportExport($event)"
            ></app-table-header>
        </div>

        <div class="table-container" [ngStyle]="{'minHeight.px': options?.minHeight ? options?.minHeight : '', 'background-color': options.backgroundColor}" [class.teams]="teamsApp">
            <div *ngIf="(tableService.loading$ | async) === false" class="content-cont h100p">
                <!-- Table Intro Stuff -->
                <div class="table-intro" [ngClass]="{'hide': options.hideTableIntro}">
                    <!-- Title -->
                    <div class="title-row">
                        <div class="title"
                            [ngClass]="{'light': lightTheme}"
                            [ngStyle]="{
                                'text-align': options.title.centered ? 'center' : 'left',
                                'font-size.px': options.title.fontSize,
                                'padding-left.px': options.title.paddingLeft
                            }"
                            *ngIf="options.title"
                        >
                            <a *ngIf="!!options.back" (click)="onBack()"><i class="fas fa-chevron-left"></i></a>
                            {{ options.translate === false ? options.title.string : (options.title.string | translate) }}
                        </div>

                        <div class="title-row-right">
                            <div class="row-count mR10" *ngIf="options.rowCount">
                                {{ 'data-source.rows' | translate:{count:(slicedRows ? slicedRows.length : 0)} }}
                            </div>
                            <div class="row-count mR10" *ngIf="options.duration">
                                {{ 'data-source.duration' | translate:{seconds:options.duration} }}
                            </div>
                            <div class="time-ago-toggle cPointer mR10" *ngIf="options.timeAgoColumnToggle">
                                <mat-slide-toggle
                                    color="accent"
                                    (change)="handleTimeAgoDateToggle()"
                                ></mat-slide-toggle>
                                <span class="label">{{ 'table.relativeTimeLabel' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Table start -->
                <div class="folder-tree-table-container" [ngStyle]="{'padding.px': options.tablePadding ?? 15}" (wheel)="wheelEvent$.next($event)">
                    <div class="main-table-container" *ngIf="hasCurRows() || !options.noData?.isContentTemplate">
                        <div class="header-body-container table">
                            <div *ngIf="!hasCurRows() && !options.noData">
                                <div class="no-data-simple">
                                    {{'no-data' | translate}}
                                </div>

                                <div
                                    *ngIf="options.endMessageKey"
                                    class="end-message"
                                    [ngStyle]="options.endMessageNgStyle"
                                >
                                    {{ options.endMessageKey | translate }}
                                </div>
                            </div>

                            <div class="no-data-simple" *ngIf="noSearchResults">
                                {{'noResults' | translate:{query: searchQuery} }}
                            </div>

                            <div class="no-data" *ngIf="!hasCurRows() && options.noData && !noSearchResults">
                                <div class="no-data-wrapper">
                                    <app-icon *ngIf="options.noData.icon" class="no-data-icon" [faIcon]="options.noData.icon"></app-icon>

                                    <div matTooltip="{{ getPermissionTooltip('table.addItem') }}" class="icon">
                                        <button type="button" *ngIf="options.noData.canAdd !== false" [disabled]="!hasEditPermission()" class="primary" (click)="addRow()" id="add-table-item-button">
                                            {{ getNoDataMessage() }}
                                        </button>
                                    </div>

                                    <div *ngIf="options.noData.canAdd === false" class="icon">
                                        {{ getNoDataMessage() }}
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="teamsApp && hasCurRows()" class="table-actions flexRow jcS aiC hide-empty">
                                <div class="table-actions-container static flexRow jcS aiC w100p h100p gap5" *ngIf="staticTableEdits?.length && getSelectedKeyCount() === 0">
                                    <div *ngFor="let e of staticTableEdits"
                                        class="table-actions-item flexRow jcS aiC gap10"
                                        [class.hidden]="e.show && e.show() === false"
                                        [ngClass]="{disabled: e.disabled}"
                                        (click)="tableEditClick(e)"
                                    >
                                        <i class="icon" [ngClass]="e.icon"></i><div>{{ translationService.getImmediate(e.title) }}</div>
                                    </div>
                                </div>
                                <div class="table-actions-container flexRow jcS aiC w100p h100p gap5" *ngIf="dynamicTableEdits?.length && getSelectedKeyCount()">
                                    <div *ngFor="let e of dynamicTableEdits"
                                        class="table-actions-item flexRow jcS aiC gap10"
                                        [class.hidden]="e.show && e.show() === false"
                                        [ngClass]="{disabled: e.disabled}"
                                        (click)="tableEditClick(e)"
                                    >
                                        <i class="icon" [ngClass]="e.icon"></i><div>{{ translationService.getImmediate(e.title) }}</div>
                                    </div>
                                    <div class="table-actions-item close flexRow jcS aiC gap10" (click)="unselectAll()">
                                        <app-icon faIcon="far fa-times"></app-icon>
                                    </div>
                                </div>
                            </div>
            
                            <div class="table-header-container" *ngIf="hasCurRows() && !options.hideColumnHeaders">
                                <div *ngIf="this.options.columnGroups?.length" class="table-header-group">
                                    <div
                                        *ngFor="let group of this.options.columnGroups; let i = index"
                                        class="table-header-group-item"
                                        [id]="'col-group-' + i"
                                        [style.width.px]="getHeaderGroupWidth(group, i === 0)"
                                    >
                                        <span class="table-header-group-item-label">{{ (group.title | translate) | uppercase }}</span>
                                    </div>
                                </div>
                                <div #tableHeader class="table-header-row" [ngStyle]="{'padding-left.px': options?.columnAlignmentOffset ? options.columnAlignmentOffset : undefined}">

                                    <div id="col-checkbox" class="table-col checkbox" *ngIf="hasEditPermission() && options?.headerOptions?.selectable" [class.has-row-icon-fixed-width]="options?.rowIconFixedWidth">
                                        <mat-checkbox (click)="selectAll(); false" [checked]="areAllSelected()"></mat-checkbox>
                                    </div>

                                    <!-- Headers -->
                                    <div *ngFor="let head of options.columns"
                                        #colHeaderContainer
                                        [id]="head.id"
                                        (click)="(head.sort && head.sort.sortable === true) && onSortClick(head)"
                                        class="table-col"
                                        [ngClass]="{
                                            'pen': (head.sort && head.sort.sortable === false) && !head?.columnHeadTooltip,
                                            'cDefault': (head.sort && head.sort.sortable === false) && head?.columnHeadTooltip,
                                            'cPointer': head.sort && head.sort.sortable === true
                                        }"
                                        [ngStyle]="{'min-width.px': head.minWidth, 'max-width.px': head.maxWidth}"
                                        [matTooltip]="head.hideColumnHeadTooltipWhenFit && colHeaderContainer.offsetWidth > colHeader.offsetWidth ? '' : head.columnHeadTooltip"
                                    >
                                        <div class="col-val">
                                            <app-icon *ngIf="head.titlePrefixIcon" [icon]="head.titlePrefixIcon" class="col-val-icon"></app-icon>

                                            <p #colHeader>{{ head.preserveCase ? (head.translate === false ? head.title : (head.title | translate)) : (head.translate === false ? head.title : (head.title | translate)) | uppercase }}</p>
                                            <span class="mL15"><i *ngIf="head.sort && head.sort.selected" class="sort far fa-chevron-down" [ngClass]="head.sort.sortAsc ? 'flipped' : '' "></i></span>
                                        </div>
                                    </div>

                                    <!-- Edit col -->
                                    <div id="col-edit" class="table-col edit-col" *ngIf="maxEdits.length || options.folderType">
                                        <!-- Add edit icons to get the correct column width -->
                                        <div class="table-col-icons">
                                            <button *ngFor="let edit of maxEdits" class="invisible"></button>
                                            <ng-container *ngIf="options.folderType">
                                                <button class="invisible"></button>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr class="table-header-separator" *ngIf="options.showHeaderSeparator" />

                            <ng-container *ngIf="options.paginate">
                                <cdk-virtual-scroll-viewport
                                    #tableBody
                                    *ngIf="hasCurRows()"
                                    itemSize="50"
                                    minBufferPx="1000"
                                    maxBufferPx="2000"
                                    class="table-body-scroll"
                                    (scroll)="options.paginate.isLazyLoaded ? lazyloadItems$.next($event) : ''"
                                    [ngStyle]="{'height.px': (options.isSubTable && subTableHeight) ? subTableHeight : ''}"
                                >
                                    <div class="row-container pB30 h100p" [ngClass]="{'list-container pRel': true}"
                                    cdkDropList (cdkDropListDropped)="rowDropped($event)"[cdkDropListSortingDisabled]="true" #dragContainer>
                                        <div *cdkVirtualFor="let row of slicedRows"
                                            [ngClass]="{'selected': row.selected, 'cPointer': rowsClickable && row.clickable}"
                                            (click)="rowsClickable && row.clickable && rowClick(row, false)"
                                            (dblclick)="rowsDblClickable && rowClick(row, true)"
                                            cdkDrag
                                            [cdkDragData]="row"
                                            cdkDragBoundary=".list-container"
                                            [cdkDragDisabled]="row.locked || !options.folderType || dragDisabled"
                                            (cdkDragMoved)="rowDragged($event)"
                                        >
                                            <a id="table-row" [ngClass]="{'cDefault': !rowsClickable || !row.clickable}" [routerLink]="getRowLink(row)" [queryParams]="getRouteParams(row)" (click)="rowsClickable && row.clickable && handleAnchorClick($event, row)"
                                                draggable="false" (dragstart)="$event.preventDefault()">
                                                <div class="table-row" [ngStyle]="{'backgroundColor': options.rowBackgroundColor}">
                                                    <ng-container *ngTemplateOutlet="tableRow; context: {row: row, colKey: colKey}"></ng-container>
                                                </div>
                                            </a>
                                        </div>

                                        <div *ngIf="options.paginate.isLazyLoaded && isLazyLoading" class="lazy-loader flexRow jcC aiC">
                                            <app-logo-spinner [size]="40" [diameter]="20" [slow]="false" [showMessage]="false"></app-logo-spinner>
                                        </div>
                                    </div>
                                </cdk-virtual-scroll-viewport>
                                <app-paginator [items]="rows" (changePage)="onChangePage($event)" [pageSize]="options.paginate.pageSize" [totalItems]="totalItems"></app-paginator>
                            </ng-container>

                            <ng-container *ngIf="!options.rowsExpandContainer && !options.paginate">
                                <cdk-virtual-scroll-viewport
                                    #tableBody
                                    *ngIf="hasCurRows()"
                                    itemSize="50"
                                    minBufferPx="1000"
                                    maxBufferPx="2000"
                                    class="table-body-scroll"
                                    (scroll)="handleScroll($event)"
                                    [ngStyle]="{'height.px': (options.isSubTable && subTableHeight) ? subTableHeight : ''}"
                                >
                                    <div class="row-container list-container pRel"
                                        cdkDropList
                                        (cdkDropListDropped)="rowDropped($event)"
                                        [cdkDropListSortingDisabled]="true"
                                        #dragContainer
                                    >
                                        <div *cdkVirtualFor="let row of slicedRows; let last = last;"
                                            [ngClass]="{'selected': row.selected, 'cPointer': rowsClickable && row.clickable}"
                                            (click)="rowsClickable && row.clickable && rowClick(row, false)"
                                            (dblclick)="rowsDblClickable && rowClick(row, true)"
                                            cdkDrag
                                            [cdkDragData]="row"
                                            cdkDragBoundary=".list-container"
                                            [cdkDragDisabled]="row.locked || !options.folderType || dragDisabled"
                                            (cdkDragMoved)="rowDragged($event)">
                                            <a id="table-row" [ngClass]="{'cDefault': !rowsClickable || !row.clickable}" [routerLink]="getRowLink(row)" [queryParams]="getRouteParams(row)" (click)="rowsClickable && row.clickable && handleAnchorClick($event, row)">
                                                <div class="table-row" [ngStyle]="row.style">
                                                    <ng-container *ngTemplateOutlet="tableRow; context: {row: row, colKey: colKey}"></ng-container>
                                                </div>
                                            </a>

                                            <div
                                                *ngIf="last && options.endMessageKey && noMoreItems"
                                                class="end-message"
                                                [ngStyle]="options.endMessageNgStyle"
                                            >
                                                {{ options.endMessageKey | translate }}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div *ngIf="totalsRow" class="totals-row-bottom-buffer"></div>

                                </cdk-virtual-scroll-viewport>
                            </ng-container>

                            <!-- Table rows will expand the container they live in rather than scroll themselves -->
                            <ng-container *ngIf="options.rowsExpandContainer">
                                <div *ngIf="hasCurRows()">
                                    <div class="row-container list-container pRel"  cdkDropList (cdkDropListDropped)="rowDropped($event)"
                                    [cdkDropListSortingDisabled]="true" #dragContainer>
                                        <div *ngFor="let row of slicedRows; trackBy: trackByKey"
                                            [ngClass]="{'selected': row.selected, 'cPointer': rowsClickable && row.clickable}"
                                            (click)="rowsClickable && row.clickable && rowClick(row, false)"
                                            (dblclick)="rowsDblClickable && rowClick(row, true)"
                                            cdkDrag [cdkDragData]="row" cdkDragBoundary=".list-container" [cdkDragDisabled]="!options.folderType || dragDisabled"
                                            (cdkDragMoved)="rowDragged($event)"
                                        >
                                            <a id="table-row" [ngClass]="{'cDefault': !rowsClickable || !row.clickable}" [routerLink]="getRowLink(row)" [queryParams]="getRouteParams(row)" (click)="rowsClickable && row.clickable && handleAnchorClick($event, row)"
                                                draggable="false" (dragstart)="$event.preventDefault()">
                                                <div class="table-row">
                                                    <ng-container *ngTemplateOutlet="tableRow; context: {row: row, colKey: colKey}"></ng-container>
                                                </div>
                                            </a>
                                        </div>
                                    </div>

                                    <!-- Show more button -->
                                    <div class="show-more" *ngIf="pageEnd !== curRows.length - 1 && curRows.length > 0">
                                        <div class="show-more-left"></div>
                                        <div class="show-more-middle">
                                            <button class="show-more-button" (click)="showMore(false)">
                                                {{'table.showMore' | translate | uppercase}}
                                            </button>
                                        </div>
                                        <div class="show-more-right"></div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <!-- Sticky overlay area -->
                    <div class="sticky-overlay" *ngIf="(showBackToTop && options.showStickyButtons !== false) || totalsRow">
                        <div class="sticky-container">
                            <button class="primary to-top-button show-more-button"
                                *ngIf="showBackToTop && options.showStickyButtons !== false"
                                (click)="backToTop()"
                                matTooltip="{{ 'table.toTop' | translate }}"
                            >
                                <i class="fas fa-arrow-up"></i>
                            </button>

                            <div class="fixed-totals-row" *ngIf="totalsRow">
                                <ng-container *ngTemplateOutlet="tableRow; context: {row: totalsRow, colKey: colKey, totalRowConfig: { 'show': true, 'count': allRows.length } }"></ng-container>
                            </div>
                        </div>
                    </div>
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    </div>

    <div class="right-container" *ngIf="options?.sideBarFilters?.show">
        <div class="right-header hide-empty"><ng-content select="[rightHeader]"></ng-content></div>
        <div class="table-filter">
            <app-table-filter [multiCategorySelect]="true" [filterResults]="tableService.filterSidebarMap"></app-table-filter>
        </div>
    </div>
</div>

<app-modal
    *ngIf="modalInput"
    [modalInput]="modalInput"
    (modalCloseOutput)="toggleModal(false)"
>
    <ng-container [ngSwitch]="activeModalType">
        <app-parent-edit-form
            *ngSwitchCase="modalType.parent"
            class="w100p"
            [folderType]="options.folderType"
            [facilityId]="options.facilityId"
            [permission]="options.editPermissionAction"
            [parentId]="currentFolderId"
            (formCancelOutput)="toggleModal(false)"
            (formSubmitOutput)="changeParent(activeRow, $event)"
        ></app-parent-edit-form>
    </ng-container>
</app-modal>

<ng-template #tableRow let-row="row" let-colKey="colKey" let-totalRowConfig="totalRowConfig">
    <app-table-row
        [row]="row"
        [colKey]="colKey"
        [inTableThatIsMoveable]="inTableThatIsMoveable"
        [canMove]="canMove(row)"
        [moveTooltip]="getPermissionTooltip('table.move', row)"
        [dummyEdits]="getDummyEdits(row.edits.length)"
        [icon]="row.icon"
        [iconFixedWidth]="row.iconFixedWidth"
        [backgroundColor]="options.rowBackgroundColor ?? row?.backgroundColor"
        [totalRowConfig]="totalRowConfig"
        (checkAction)="selectRow(row)"
        (columnClick)="columnClick($event, row)"
        (editClick)="colEditClick($event, row)"
        (colPrefixClick)="colPrefixClick($event, row)"
        (colPostfixClick)="colPostfixClick($event, row)"
        (editClick)="handleEditColClick($event, row)"
        (overrideSelection)="handleOverrideSelection($event, row)"
    ></app-table-row>
</ng-template>

<app-folder-editor-modal
    [open]="folderEditorOpen"
    [folderId]="selectedFolderId"
    [parentFolderId]="folderId"
    [folderType]="options.folderType"
    [viewPermissionAction]="options.viewPermissionAction"
    [editPermissionAction]="options.editPermissionAction"
    [submitPermissionAction]="options.submitPermissionAction"
    (closed)="handleFolderEditorModalClose($event)"
></app-folder-editor-modal>
